const setEnvironment = async () => {
    if (process.env.NODE_ENV === "development") {
        const {env} = await import("./env");

        let envs = Object.keys(env);

        for (let _env of envs) {
            window[_env] = env[_env];
        }

        window.ENDPOINT_HTTP = `${env.PROTOCOL_HTTP}://${window.DOMAIN}/explore`;
        window.ENDPOINT_WS = `${env.PROTOCOL_WS}://${window.DOMAIN}/graphql`;
        window.ENDPOINT = `${env.PROTOCOL_HTTP}://${window.DOMAIN}`;
    } else {
        window.DOMAIN = window.location.host;
        window.PROTOCOL_HTTP = window.location.protocol;
        window.PROTOCOL_WS = window.location.protocol === "https:" ? "wss" : "ws";
        window.ENDPOINT_HTTP = `${window.PROTOCOL_HTTP}//${window.DOMAIN}/explore`;
        window.ENDPOINT_WS = `${window.PROTOCOL_WS}://${window.DOMAIN}/graphql`;
        window.ENDPOINT = `${window.PROTOCOL_HTTP}//${window.DOMAIN}`;
    }

    await import("./main");
};

setEnvironment();
